import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { LoginActions, checkLoginSuccess, fetchProvidersSuccess } from './actions';
import { IdentityProvider, LoginStatus, Status } from './model';
import { AnyAction } from 'redux';

function* handleFetch(guid: AnyAction) {
    try {
        //const response: Response = yield fetch(`auth/status?&login_id_hash=${guid.payload}`, {
        const response: Response = yield fetch(`${process.env.REACT_APP_BACKEND_URL || ""}auth/status`, {
            credentials: 'include'
        }).then(response => response)
        const statusObject: Status = yield response.json();
        const login: LoginStatus = { isLoggedIn: response.status === 200, status: statusObject }
        yield put(checkLoginSuccess(login));
    } catch (error) {
        console.error(error);
    }
}

function* watchFetchRequest() {
    yield takeLatest(LoginActions.CHECKLOGIN_REQUEST, handleFetch);
}

function* fetchProviders() {
    try {
        const providers: IdentityProvider[] = yield fetch(`${process.env.REACT_APP_BACKEND_URL || ""}auth/id_providers`).then(response => response.json())
        yield put(fetchProvidersSuccess(providers));
    } catch (error) {
        const emptyProviders: IdentityProvider[] = [];
        yield put(fetchProvidersSuccess(emptyProviders));
    }
}

function* watchFetchProvidersRequest() {
    yield takeLatest(LoginActions.FETCH_PROVIDERS_REQUEST, fetchProviders);
}

function* Logout() {
    yield fetch(`${process.env.REACT_APP_BACKEND_URL || ""}auth/logout`, {
        credentials: 'include'
    }).then(response => {
        if (response.status !== 200) console.error("bad login response status", response.status)
    }).catch(error => console.error(error));    
}

function* watchLogoutRequest() {
    yield takeLatest(LoginActions.LOGOUT, Logout);
}


function* jobSaga() {
    yield all([
        fork(watchFetchRequest),
        fork(watchFetchProvidersRequest),
        fork(watchLogoutRequest)
    ]);
}

export default jobSaga;