import * as React from "react";
import "../i18n";
import "../theme/index.scss"

import Main from './Main';
import configureStore from './store';
import { render } from 'react-dom';

const store = configureStore();

render(<Main store={store} />, document.querySelector('#app'));
