import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';

import { createRootReducer, rootSaga } from '../store';

class Store {
  sagaMiddleware: SagaMiddleware;

  constructor() {
    this.sagaMiddleware = createSagaMiddleware();
  }

  private getEnhancer() {
    return composeWithDevTools(
      applyMiddleware(this.sagaMiddleware),
    );
  }

  getRootReducer() {
    return createRootReducer();
  }

  getSagaMiddleware() {
    return this.sagaMiddleware;
  }

  getStore() {
    return createStore(this.getRootReducer(), this.getEnhancer());
  }
}

export default () => {
  const storeHelper = new Store();

  const store = storeHelper.getStore();
  const sagaMiddleware = storeHelper.getSagaMiddleware();

  sagaMiddleware.run(rootSaga);

  return store;
};
