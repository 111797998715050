import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import AppState from './AppState';

import loginSaga from './login/sagas';
import loginReducer from './login/reducer';
export * from './login/actions';
export { default as loginSelectors } from './login/selectors';

export const createRootReducer = () => combineReducers<AppState>({
    login: loginReducer
});

export function* rootSaga() {
    yield all([fork(loginSaga)]);
}