import { ThemeProvider } from '@material-ui/styles';
import * as React from 'react'
import { mainRedirects, mainRoutes } from '../../hoc/routes';
import { Router, AdminTheme } from '../../modules';

export default class App extends React.PureComponent {
  render() {
    return (
      <ThemeProvider theme={AdminTheme}>
            <Router routes={mainRoutes} redirects={mainRedirects} />
      </ThemeProvider>
    );
  }
}
