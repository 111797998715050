import * as React from "react";
import { hot } from 'react-hot-loader';
import { Provider as ReduxProvider } from 'react-redux';
import { Store } from 'redux';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { App } from '../hoc/app';
import AppState from '../store/AppState';

interface Props {
  store: Store<AppState>;
}

const Main: React.FunctionComponent<Props> = props => (
    <React.Suspense fallback="loading">
      <ReduxProvider store={props.store}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ReduxProvider>
    </React.Suspense>
);

export default hot(module)(Main);
