import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

export const defaultLanguage = "en-US";

function loadLocale(url: string, _: any, callback: any) {
  import(/* webpackChunkName: '[request]' */ `./translations/${url}.json`)
      .then(locale => callback(locale, { status: 200 }))
      .catch(() => callback(null, { status: 404 }));
}

i18n
  .use(initReactI18next)
  .use(XHR)
  .init({
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    backend: {
      loadPath: "{{lng}}/{{ns}}",
      parse: (data: object) => data,
      ajax: loadLocale
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
