import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
  },
  palette: {
    type: 'light',
    primary: {
      50: '#fcf2e7',
      100: '#f8dec3',
      200: '#f3c89c',
      300: '#eeb274',
      400: '#eaa256',
      main: '#e69138',
      600: '#e38932',
      700: '#df7e2b',
      800: '#db7424',
      900: '#d56217',
      A100: '#ffffff',
      A200: '#ffe3d4',
      A400: '#ffc3a1',
      A700: '#ffb287',
    },

  },
});

export default theme;
