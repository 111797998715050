import * as React from 'react'

import { Props } from './router.interfaces';
import Switch from './switch/Switch';

export default class Router extends React.PureComponent<Props> {
  render() {
    const { routes = [], redirects = [] } = this.props;
    const routerSwitch = <Switch navigates={redirects} routes={routes} />;

    return routerSwitch;
  }
}
