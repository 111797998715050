import { createSelector } from 'reselect';
import AppState from '../AppState';
import { LoginState } from './model';

const loginState = (state: AppState) => { return state.login };
const checkLogin = createSelector(loginState, (hState: LoginState) => hState);

const providerState = (state: AppState) => { return state.login };
const fetchProviders = createSelector(providerState, (hState: LoginState) => hState);

const logoutState = (state: AppState) => { return state.login };
const logout = createSelector(logoutState, (hState: LoginState) => hState);

export default {
    checkLogin,
    fetchProviders,
    logout
};