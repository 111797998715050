import * as React from "react";
import { Navigate, Route, Routes } from 'react-router';
import { BrowserRouter } from "react-router-dom";
import { Props } from './switch.interfaces';


const routerSwitch: React.FunctionComponent<Props> = (props) => {
  const { routes = [], navigates = [] } = props;

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => <Route key={index} {...route} />)}
        {navigates.map(({from, ...navigate}, index) => (
          <Route
            key={index}
            path={from}
            element={<Navigate key={index} {...navigate} />}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default routerSwitch;
