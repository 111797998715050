import { lazy } from "react";
import { Navigate, Route } from "../../modules/router/switch";

const Login = lazy(() => import('./login'));
const Logout = lazy(() => import('./logout'));
const Error = lazy(() => import('./error'));

export const mainRoutes: Route[] = [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/logout',
        element: <Logout />,
    },
    {
        path: '/error',
        element: <Error />,
    }
];

export const mainRedirects: Navigate[] = [
    {
        replace: true,
        from: '/',
        to: '/login',
    },
];
