import { action } from 'typesafe-actions';
import { IdentityProvider, LoginStatus } from './model';

export enum LoginActions {
    CHECKLOGIN = '@@login/CHECKLOGIN',
    CHECKLOGIN_REQUEST = '@@login/CHECKLOGIN_REQUEST',
    FETCH_PROVIDERS_REQUEST = '@@login/FETCH_PROVIDERS_REQUEST',
    FETCH_PROVIDERS = '@@login/FETCH_PROVIDERS',
    LOGOUT = '@@login/LOGOUT',
}

export const checkLoginSuccess = (login: LoginStatus) => action(LoginActions.CHECKLOGIN, login);
export const checkLoginRequest = (guid: string | null) => action(LoginActions.CHECKLOGIN_REQUEST, guid);

export const fetchProvidersSuccess = (providers: IdentityProvider[]) => action(LoginActions.FETCH_PROVIDERS, providers);
export const fetchProvidersRequest = () => action(LoginActions.FETCH_PROVIDERS_REQUEST);

export const logoutRequest = () => action(LoginActions.LOGOUT);
