import { Reducer } from 'redux';
import { LoginActions } from './actions';
import { LoginState } from './model';

const initialState: LoginState = {
    loginStatus: null,
    providers: { value: [], loaded: false},
};

const reducer: Reducer<LoginState> = (state = initialState, action) => {

    switch (action.type) {
        case LoginActions.CHECKLOGIN_REQUEST: {
            return { ...state };
        }
        case LoginActions.CHECKLOGIN: {
            return { ...state, loginStatus: action.payload };
        }

        case LoginActions.FETCH_PROVIDERS_REQUEST: {
            return { ...state, providers: initialState.providers };
        }

        case LoginActions.FETCH_PROVIDERS: {
            return { ...state, providers: { loaded: true, value: action.payload }};
        }

        case LoginActions.LOGOUT: {
            return { ...state };
        }

        default: {
            return state;
        }
    }
};

export default reducer;